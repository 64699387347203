import React, {useEffect, useState} from "react";
import {posHotplpassApi} from "services/posHotplpassApi";
import {storeBranchApi} from "services/storeBranchApi";
import styled from "styled-components";
import Calender from "components/orderList/calender";
import PosModal from "components/orderList/posModal";
import OrderDetailModal from "components/orderList/orderDetailModal";
import {PRETENDARD} from "components/shared/common/fontFamily";
import {ReactComponent as VogoArrowNext} from "assets/images/pos/ic_vogo_pos_arrow_next.svg";
import {ReactComponent as VogoArrowPrev} from "assets/images/pos/ic_vogo_pos_arrow_prev.svg";
import {formatDate, getKSTElapsedTime} from "utils/date";
import GhostButton from "components/common/ghostButton";
import FilterButton from "components/common/filterButton";
import {useQuery} from "react-query";
import {useNavigate} from "react-router-dom";
import Toast from "components/orderList/common/toast";
import {addSpaceEveryFourChars} from "utils/textFormatting";
import {sw} from "utils/serviceWorker";
import {posApi} from "../../services/posApi";

type Props = {
  modalSee: boolean;
  setModalSee?: any;
  initialCode?: string;
};

const OrderList = ({modalSee = false}: Props) => {
  const [posModalSee, setPosModalSee] = useState(modalSee);
  const [orderItemList, setOrderItemList] = useState<any>([]);
  const [orderItemData, setOrderItemData] = useState<any>({});
  const [orderItemCode, setOrderItemCode] = useState("");
  const [orderItemIdx, setOrderItemIdx] = useState<number>(-1);
  const [conditionType, setConditionType] = useState(0);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [isClickedDateRange, setIsClickedDateRange] = useState(false);
  const [orderDetailModalSee, setOrderDetailModalSee] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [totalPrice, setTotalPrice] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderListKey, setOrderListKey] = useState(0);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastText, setToastText] = useState("");
  const [loadOrderList, setLoadOrderList] = useState(false);
  const [windowObject, setWindowObject] = useState<any>();
  const [currentCode, setCurrentCode] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    setWindowObject(window);
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (!currentCode) {
      intervalId = setInterval(function () {
        const messageChannel = new MessageChannel();
        messageChannel.port1.onmessage = (event) => {
          const data = event.data;
          if (typeof (data) === 'object') {
            setCurrentCode(data.code);
          }
        }
        sw.postMessage(messageChannel.port2);
      }, 2000);
    } else {
      console.log('code', currentCode);
      setPosModalSee(true);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    }
  }, [currentCode]);

  const {data} = useQuery(
    ["orderList"],
    () => fetchOrderListHandler(conditionType, dateRange, currentPage),
    {enabled: !posModalSee}
  );

  useEffect(() => {
    fetchOrderListHandler(conditionType, dateRange, currentPage);
  }, [conditionType, loadOrderList]);

  const fetchTotalPriceHandler = async (dateRange: any) => {
    try {
      const formattedStartDate = formatDate(dateRange[0]);
      const formattedEndDate = formatDate(dateRange[1]);

      const result = await posHotplpassApi.getHotplpassOrderPrice(
        formattedStartDate,
        formattedEndDate
      );
      const { data, meta } = result.data;
      if (meta.code === 0) {
        setTotalPrice(data.price);
      } else {
        alert(meta.message);
      }
    } catch (error) {
      console.error("Fetching order list failed:", error);
    }
  };

  const size = 10;
  const fetchOrderListHandler = async (
    conditionType: number,
    dateRange: any,
    currentPage: number
  ) => {
    try {
      const formattedStartDate = formatDate(dateRange[0]);
      const formattedEndDate = formatDate(dateRange[1]);

      const result = await posHotplpassApi.getHotplpassOrder(
        conditionType,
        currentPage,
        size,
        "id",
        formattedStartDate,
        formattedEndDate
      );
      const { data, meta } = result.data;
      if (meta.code === 0) {
        setOrderItemList(data.content);
        setTotalPage(data.totalPages);
        setOrderItemData(data);
        setCurrentPage(data.pageable.pageNumber);
        setIsDataLoaded(true);
        setLoadOrderList(false);
      } else {
        alert(meta.message);
      }
      //
      return result;
    } catch (error) {
      console.error("Fetching order list failed:", error);
    }
  };

  const fetchOrderDetailHandler = (code: string, idx: number) => {
    setOrderDetailModalSee(true);
    setOrderItemCode(code);
    setOrderItemIdx(idx);
  };

  // 다음 페이지로 이동하는 함수
  const onNextPageClick = (currentPage: any, totalPage: any) => {
    const nextPage = currentPage + 1;
    if (nextPage < totalPage) {
      // 총 페이지 수를 초과하지 않도록 조건 추가
      fetchOrderListHandler(conditionType, dateRange, nextPage);
    }
  };

  // 이전 페이지로 이동하는 함수
  const onPrevPageClick = (currentPage: any) => {
    const prevPage = currentPage - 1;
    if (prevPage > -1) {
      // 0보다 작은 페이지로 가지 않도록 조건 추가
      fetchOrderListHandler(conditionType, dateRange, prevPage);
    }
  };

  const logout = async () => {
    const result = await storeBranchApi.deleteLogout();
    const { meta } = result.data;
    try {
      if (meta.code === 0) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        navigate("/");
      }
    } catch (error) {
      console.error("Fetching logout failed:", error);
    }
  };

  return (
    <Wrapper id={orderListKey.toString()}>
      <TitleWrapper>
        <Title>결제내역</Title>
        {/* <Field /> */}
        {/* <button onClick={() => postJwtReissue(accessToken, refreshToken)}>
          reissue
        </button> */}
        {/* <button onClick={movePage}>movePage</button> */}
        {/* <GhostButton onClick={() => {}} buttonText="test" type={"enabled"} /> */}
        {/* <button onClick={logout}>로그아웃</button> */}
        <TitleWrapper2>
          <Calender
            dateRange={dateRange}
            setDateRange={setDateRange}
            setIsClickedDateRange={setIsClickedDateRange}
            fetchTotalPriceHandler={fetchTotalPriceHandler}
            setLoadOrderList={setLoadOrderList}
            setCurrentPage={setCurrentPage}
          />
          <div style={{ display: "flex", gap: "3px" }}>
            <LabelWrapper onClick={() => posApi.retrieveCodeByTagId().then((code) => { setCurrentCode(`${code}`) })}>
              {"대기중인 상품조회"}
            </LabelWrapper>
            <LabelWrapper onClick={() => setPosModalSee(true)}>
              {"상품번호 직접입력"}
            </LabelWrapper>
            <FilterButton
              buttonText="로그아웃"
              onClick={logout}
              value={3}
              filterTypeNumber={4}
            />
          </div>
        </TitleWrapper2>
      </TitleWrapper>
      <FlexCol>
        <ListWrapper>
          <ListConditionsWrapper>
            <ListConditionsItemWrapper>
              <ListConditionsItem>
                <FilterButton
                  buttonText="전체내역"
                  onClick={() => setConditionType(0)}
                  value={0}
                  filterTypeNumber={conditionType}
                />
              </ListConditionsItem>
              <ListConditionsItem>
                <FilterButton
                  buttonText="결제취소"
                  onClick={() => setConditionType(2)}
                  value={2}
                  filterTypeNumber={conditionType}
                />
              </ListConditionsItem>
            </ListConditionsItemWrapper>

            <TotalPrice>
              합계 {totalPrice !== "" && totalPrice.toLocaleString()}원
            </TotalPrice>
          </ListConditionsWrapper>

          <TableContainer>
            <ResponsiveTable>
              <TableHeader>
                <tr>
                  <TableHeaderItem width={"7.98%"}>
                    {"순번"}
                  </TableHeaderItem>
                  <TableHeaderItem width={"9.97%"}>
                    {"사용시간"}
                  </TableHeaderItem>
                  {/* <TableHeaderItem width={"4.83%"}>
                    {"고객"}
                  </TableHeaderItem> */}
                  <TableHeaderItem width={"20.06%"}>
                    {"코드번호"}
                  </TableHeaderItem>
                  <TableHeaderItem width={"18.59%"}>
                    {"상품내역"}
                  </TableHeaderItem>
                  <TableHeaderItem width={"13.76%"}>
                    {"결제상태"}
                  </TableHeaderItem>
                  <TableHeaderItem width={"13.76%"}>
                    {"사용금액"}
                  </TableHeaderItem>
                </tr>
              </TableHeader>
              {isDataLoaded && (
                <>
                  <tbody>
                    {orderItemList.map((item: any, index: number) => {
                      if (index < 10)
                        return (
                          <EvenTableRow
                            key={item.idx}
                            onClick={() =>
                              fetchOrderDetailHandler(item.code, item.idx)
                            }
                          >
                            <TableCell width={"7.98%"}>
                              {item.idx}
                            </TableCell>
                            <TableCell width={"9.97%"}>
                              {getKSTElapsedTime(item.date)}
                            </TableCell>
                            {/* <TableCell width={"4.83%"}>
                              {item.userName}
                            </TableCell> */}
                            <TableCell width={"20.06%"}>
                              {addSpaceEveryFourChars(item.code)}
                            </TableCell>
                            <TableCell width={"18.59%"}>
                              {item.hotplpassName}
                            </TableCell>
                            <TableCell width={"13.76%"}>
                              {item.type === "USE" && "결제완료"}
                              {item.type === "REFUND" && "결제취소"}
                            </TableCell>
                            <TableCell width={"13.76%"} type={item.type}>
                              {item.price.toLocaleString("en-US") + "원"}
                            </TableCell>
                          </EvenTableRow>
                        );
                    })}
                  </tbody>
                </>
              )}
            </ResponsiveTable>
          </TableContainer>
        </ListWrapper>
        {/* TODO:: 절댓값으로 FooterWrapper 위치 조정  */}
        <FooterWrapper>
          {orderItemData && (
            <PageText>
              {totalPage !== 0 ? currentPage + 1 : 0}/{totalPage}
              페이지
            </PageText>
          )}
          {/* 페이지네이션 버튼 */}
          <PaginationButtonsWrapper>
            <PaginationButton
              onClick={() => onPrevPageClick(currentPage)}
              width={"14.4rem"}
              height={"4rem"}
              $buttontextsize={"1.4rem"}
              icon={<VogoArrowPrev />}
              type={"enabled"}
              $padding={"0px 1rem"}
            />
            <PaginationButton
              onClick={() => onNextPageClick(currentPage, totalPage)}
              width={"14.4rem"}
              height={"4rem"}
              $buttontextsize={"1.4rem"}
              icon={<VogoArrowNext />}
              type={"enabled"}
              $padding={"0px 1rem"}
            />
          </PaginationButtonsWrapper>
        </FooterWrapper>
      </FlexCol>
      {posModalSee && (
        <PosModal
          modalSee={posModalSee}
          setModalSee={setPosModalSee}
          initialCode={currentCode}
          setCurrentCode={setCurrentCode}
          setIsToastOpen={setIsToastOpen}
          setToastText={setToastText}
          setOrderListKey={setOrderListKey}
          windowObject={windowObject}
        />
      )}
      {orderDetailModalSee && (
        <OrderDetailModal
          orderModalSee={orderDetailModalSee}
          setOrderModalSee={setOrderDetailModalSee}
          orderItemCode={orderItemCode}
          orderItemIdx={orderItemIdx}
          setIsToastOpen={setIsToastOpen}
          setToastText={setToastText}
        />
      )}
      <Toast
        text={toastText}
        toast={isToastOpen}
        setToast={setIsToastOpen}
        onToastComplete={() =>
          fetchOrderListHandler(conditionType, dateRange, currentPage)
        }
      />
    </Wrapper>
  );
};

export default OrderList;

const Wrapper = styled.div`
  margin: 3.8rem 0 0;
  padding: 0 3.6rem;
  text-align: center;
  width: 100%;
`;

const FooterWrapper = styled.div`
  width: 100%;
  margin-top: 3.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PaginationButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const TableContainer = styled.div`
  overflow-x: auto;
  margin-top: 2.5rem;
  height: 53.8rem;
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
`;

const TableHeader = styled.thead`
    width: 100%;
    height: 2.8rem;
    margin-top: 2rem;
    background-color: #f1f3f5;
    border-top: 1px solid #dfe5e8;
    border-bottom: 1px solid #dfe5e8;
    color: #343a40;
    text-align: center;

    /* Caption/Caption_13_B */
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 138.462%; /* 18px */
    letter-spacing: -0.2px;
`;

const TableHeaderItem = styled.th<{ width: string }>`
  /* padding: 0.5rem 1.6rem; */
  padding: 0.5rem 1.6rem;
  white-space: nowrap; /* 텍스트를 한 줄로 표시 */
  ${({ width }) => width && `width: ${width};`}
`;

const TableCell = styled.td<{ width: string; type?: string }>`
  color: #343a40;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 160% */
  letter-spacing: -0.4px;
  /* padding: 1.6rem 1.6rem; */
  padding: 1rem 1.6rem;
  white-space: nowrap; /* 텍스트를 한 줄로 표시 */
  ${({ width }) => width && `width: ${width};`}
  ${({ type }) =>
    type === "REFUND" &&
    `color: #E02020; 
    text-decoration: line-through;
    &::before{
      content: '-';
    }`}
`;

const EvenTableRow = styled.tr`
  justify-content: space-between;
  border-bottom: 1px solid #dfe5e8;
  cursor: pointer;
  white-space: nowrap; /* 텍스트를 한 줄로 표시 */
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Title = styled.p`
  color: #252525;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.05rem;
  margin-top: 0.2rem;
`;

const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ListConditionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10.2rem;
`;

const ListConditionsItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.8rem;
`;

const ListConditionsItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
const TitleWrapper2 = styled.div`
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LabelWrapper = styled.div`
  width: 14rem;
  height: 3.6rem;
  border-radius: 10px;
  background: #ede9ff;
  color: #6728ff;

  /* Body/Body_14_R */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.2px;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  cursor: pointer;
`;

const TotalPrice = styled.div`
  color: #4a5561;
  text-align: right;

  /* Body/Body_14_B */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 142.857%; /* 20px*/
  letter-spacing: -0.2px;
`;

const PageText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #343a40;

  /* Body/Body_15_R */
  font-family: ${PRETENDARD};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 24px */
  letter-spacing: -0.4px;
`;

const PaginationButton = styled(GhostButton)``;
