import {Input} from "components/common/Input";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {posApi} from "services/posApi";
import {useAuthContext} from "store/context/authContext";
import KeyPad from "./keyPad";
import styled from "styled-components";
import Button from "components/common/Button";
import {addSpaceEveryFourChars, formatPriceToNumber, removeAllSpaces,} from "utils/textFormatting";
import {ERROR} from "constants/errCode";
import {ERROR_MSG} from "constants/errMessages";
import GetCodeTimer from "./common/getCodeTimer";
import {setLocalStorage} from "utils/storage";
import {useQuery} from "react-query";

type Props = {
  modalSee: boolean;
  setModalSee?: any;
  initialCode?: string;
  setCurrentCode?: any;
  setIsToastOpen: any;
  setToastText: any;
  setOrderListKey?: any;
  windowObject: any;
};

interface ApiResponse {
  meta: {
    code: number;
    message?: string;
  };
  data?: {
    hotplpassName: string;
    balance: number;
  };
}

interface ErrorResponse {
  response: {
    data: ApiResponse;
    status: number;
  };
}

const PosModal = ({
  modalSee,
  setModalSee,
  initialCode,
  setCurrentCode,
  setIsToastOpen,
  setToastText,
}: Props) => {
  const [code, setCode] = useState("");
  const [orderPrice, setOrderPrice] = useState("");
  const [productName, setProductName] = useState("");
  const [balance, setBalance] = useState("");
  const { accessToken } = useAuthContext();
  const [errMsg, setErrMsg] = useState("");
  const [fetchFlag, setFetchFlag] = useState(false); // input focus를 지정해주는 데 사용하는 state
  const codeInputRef = useRef<HTMLInputElement | null>(null);
  const orderPriceInputRef = useRef<HTMLInputElement | null>(null);
  const [focusedInput, setFocusedInput] = useState("code");
  const [placeholderText, setPlaceHolderText] = useState("");
  // todo :: timer 부분 object로 만들기
  const [isTimerStart, setIsTimerStart] = useState(false); // 조회 timer state
  const [showCodeTimer, setShowCodeTimer] = useState(false);
  const TIMER_TIME = 60;
  const CODE_LENGTH = 19;
  const [timerTime, setTimerTime] = useState(TIMER_TIME);

  useEffect(() => {
    if (initialCode && initialCode.length > 0) {
      setCode(addSpaceEveryFourChars(initialCode));
      fetchHotplCode(initialCode);
    }
  }, [initialCode]);

  function parseErrMsg(meta: { code: number, message?: string }) {
    let errMsg: string;
    let errPlaceholderMsg: string;
    switch (meta.code) {
      case ERROR.HOTPLACE_PASS_INFO_QUERY.PRODUCT_UNAVAILABLE.CODE:
        errMsg = ERROR.HOTPLACE_PASS_INFO_QUERY.PRODUCT_UNAVAILABLE.MESSAGE;
        errPlaceholderMsg =
            ERROR.HOTPLACE_PASS_INFO_QUERY.PRODUCT_UNAVAILABLE.PLACEHOLDER;
        break;
      case ERROR.HOTPLACE_PASS_INFO_QUERY.INVALID_PRODUCT.CODE:
      case ERROR.HOTPLACE_PASS_INFO_QUERY.INVALID_HOTPLACE_PASS.CODE:
        errMsg = ERROR.HOTPLACE_PASS_INFO_QUERY.INVALID_PRODUCT.MESSAGE;
        errPlaceholderMsg =
            ERROR_MSG.HOTPLACE_PASS_INFO_QUERY.INVALID_GIFT_CARD;
        break;
      case ERROR.HOTPLACE_PASS_INFO_QUERY.NO_BALANCE.CODE:
        errMsg = "";
        errPlaceholderMsg = ERROR_MSG.HOTPLACE_PASS_INFO_QUERY.NO_BALANCE;
        break;
      default:
        errMsg =
            meta.message || ERROR_MSG.HOTPLACE_PASS_INFO_QUERY.UNKNOWN_ERROR;
        errPlaceholderMsg = "";
        break;
    }
    return {errMsg, errPlaceholderMsg};
  }

  const fetchHotplCode = async (code: string) => {
    setTimerTime(TIMER_TIME); // 타이머를 60으로 설정
    try {
      const hotplCode = removeAllSpaces(code);
      const result = await posApi.getHotplCode(hotplCode);

      if (result.data && result.data.meta) {
        const { meta, data } = result.data;
        if (meta.code === 0 && data) {
          startTimer(); // 타이머 시작
          setProductName(data.hotplpassName);
          setBalance(data.balance);
          setFetchFlag(true);
          setFocusedInput("orderPrice");
          setErrMsg("");
        } else {
          setShowCodeTimer(false);
        }
      } else {
        console.error("Invalid response structure");
      }
    } catch (error) {
      const err = error as ErrorResponse;
      if (err?.response?.data?.meta) {
        const { meta } = err.response.data;
        const {errMsg, errPlaceholderMsg} = parseErrMsg(meta);
        setErrMsg(errMsg);
        setPlaceHolderText(errPlaceholderMsg);
      } else {
        console.error("Error response has invalid structure");
      }
    }
  };

  const openToast = () => {
    setIsToastOpen(true);
    setToastText("상품 결제가 완료되었습니다.");
  };

  const fetchHotplPassBalance = async (
    code: string,
    price: string,
    token: any
  ) => {
    try {
      code = removeAllSpaces(code);
      const orderTotalPrice = formatPriceToNumber(price);
      if (orderTotalPrice < 100) {
        return;
      }

      const result = await posApi.postUseHotplCode(code, orderTotalPrice, token);
      const { meta } = result.data;
      if (meta.code === 0) {
        // 결제가 성공했을 때
        setModalSee(false);
        setCurrentCode('');
        setShowCodeTimer(false); // timer 컴포넌트 보이지 않게끔
        setLocalStorage("orderSuccess", true);
        openToast();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    // 상품코드 자릿수가 19자리 이하일 때 값을 초기화
    if (value.length < CODE_LENGTH) {
      clearModalData(true);
    }
    const lastChar = value.charAt(value.length - 1);
    // 마지막 입력이 문자인 경우
    if (isNaN(Number(lastChar))) {
      value = value.replace(lastChar, "");
    }
    const formatvalue = addSpaceEveryFourChars(value);
    setCode(formatvalue);
  };

  const onNumberKeyBtnClickHandler = (e: any) => {
    const { value } = e.target;
    if (focusedInput === "code" && code.length < CODE_LENGTH) {
      clearModalData(false);
      setCode((prevCode) => addSpaceEveryFourChars(prevCode + value));
    } else if (focusedInput === "orderPrice") {
      setOrderPrice((prevOrderPrice) => prevOrderPrice + value);
    }
  };

  const onCloseModal = () => {
    setModalSee(false);
    clearModalData(true);
    setCurrentCode(null);
  };

  // modal data의 값을 초기화 하는 함수
  const clearModalData = (isCodeValueClear: boolean) => {
    if (isCodeValueClear) {
      setCode("");
    }
    setProductName("");
    setBalance("");
    setFetchFlag(false);
    setErrMsg("");
    setPlaceHolderText("");
    setIsTimerStart(false);
    setShowCodeTimer(false);
  };

  const BackButtonHandler = () => {
    if (focusedInput === "code") {
      if (code.length > 0 && code.length <= CODE_LENGTH) {
        clearModalData(false);
        setCode((prevCode) => addSpaceEveryFourChars(prevCode.slice(0, -1)));
      }
    } else if (focusedInput === "orderPrice") {
      if (orderPrice.length > 0 && code.length <= CODE_LENGTH) {
        setOrderPrice((prevOrderPrice) => prevOrderPrice.slice(0, -1));
      }
    }
  };

  const onClearButtonHandler = () => {
    setErrMsg("");
    if (focusedInput === "code") {
      clearModalData(true);
    } else if (focusedInput === "orderPrice") {
      setOrderPrice("");
    }
  };

  // 잔액 라벨을 클릭했을 때
  const onClickPriceLabelHandler = () => {
    setOrderPrice(balance.toString().split(",").join(""));
  };

  const onPriceHandler = (price: string, balance: string) => {
    let orderPrice = Number(price);
    let balances = Number(balance);
    if (orderPrice > balances) {
      orderPrice = balances;
      setOrderPrice(orderPrice.toString());
    }
    return orderPrice.toLocaleString("en-US") + " 원";
  };

  const determineCodeGetButtonType = (codeLength: number) => {
    return codeLength === CODE_LENGTH ? "enabled" : "disabled";
  };

  const determineOrderButtonType = (price: string) => {
    const orderTotalPrice = formatPriceToNumber(price);
    return orderTotalPrice !== 0 && isTimerStart ? "enabled" : "disabled";
  };

  //
  const [initialCodes, setInitialCodes] = useState<string>(""); // 초기 상태값은 빈 문자열로 설정

  const startTimer = () => {
    const randomCode = Math.floor(Math.random() * 1000000); // 무작위 숫자 생성
    setInitialCodes(randomCode.toString()); // 생성된 숫자를 문자열로 설정
    setShowCodeTimer(true); // timer 컴포넌트 보이게끔
    setIsTimerStart(true); // timer 시작
    setTimerTime(TIMER_TIME); // 60초로 초기화
  };

  const {data} = useQuery(
    ["orderList"],
    () => fetchHotplPassBalance(removeAllSpaces(code), orderPrice, accessToken),
    {
      staleTime: 0,
      refetchOnWindowFocus: "always",
    }
  );

  return (
    <Wrapper>
      <Dim $isopen={modalSee}>
        <Modal>
          <Title>상품결제</Title>
          {/* <button onClick={close}>닫기</button> */}
          {/* <button onClick={openToast}>toast</button> */}
          <ModalWrapper>
            <LeftSide>
              <InputListWrapper>
                <InputTimerWrapper>
                  <InputWrapper>
                    <div onClick={() => setFocusedInput("code")}>
                      <Input
                        type={"text"}
                        name={"code"}
                        value={code}
                        width={"22.4rem"}
                        height={"5.2rem"}
                        onChange={onChangeHandler}
                        placeholder={"상품코드를 입력해주세요."}
                        label={"상품코드"}
                        required={"required"}
                        textAlign={"center"}
                        readOnly={false}
                        maxLength={CODE_LENGTH}
                        inputStyleType={"default"}
                        // ref={codeInputRef}
                      />
                    </div>
                    <div style={{ marginTop: "1.7rem" }}>
                      <SButton
                        buttonText="조회"
                        type={determineCodeGetButtonType(
                          addSpaceEveryFourChars(code).length
                        )}
                        onClick={() => {
                          setTimerTime(TIMER_TIME);
                          fetchHotplCode(removeAllSpaces(code));
                        }}
                        width={"8.6rem"}
                      />
                    </div>
                  </InputWrapper>
                  <TimerWrapper>
                    {/* 조회 버튼을 누를 시 타이머 동작 */}
                    {showCodeTimer && (
                      <>
                        <GetCodeTimer
                          timeSeconds={timerTime}
                          isTimerStart={isTimerStart}
                          setIsTimerStart={setIsTimerStart}
                          setShowCodeTimer={setShowCodeTimer}
                          initialCode={initialCodes} // initialCode 전달
                        />
                      </>
                    )}
                  </TimerWrapper>
                </InputTimerWrapper>

                <Input
                  type={"text"}
                  name={"productName"}
                  value={productName}
                  width={"32rem"}
                  height={"5.2rem"}
                  onChange={onChangeHandler}
                  placeholder={
                    placeholderText !== ""
                      ? placeholderText
                      : "상품 코드를 조회해주세요."
                  }
                  label={"상품명"}
                  required={"required"}
                  textAlign={"center"}
                  readOnly={true}
                  inputStyleType={"default"}
                  errMsg={errMsg}
                  // ref={null}
                />
                <BalanceWrapper>
                  {balance && (
                    <BalanceLabel onClick={onClickPriceLabelHandler}>
                      <BalanceLabelTitle>잔액</BalanceLabelTitle>
                      {Number(balance).toLocaleString("en-US")}원
                    </BalanceLabel>
                  )}
                </BalanceWrapper>
              </InputListWrapper>

              <OrderPriceWrapper
                onClick={() => {
                  if (fetchFlag) setFocusedInput("orderPrice");
                }}
              >
                <OrderTitle>결제금액</OrderTitle>
                <NumberKeyInput2
                  id="orderPrice"
                  name="orderPrice"
                  type="text"
                  value={onPriceHandler(orderPrice, balance)}
                  placeholder={"결제금액을 입력해주세요"}
                  readOnly
                  ref={orderPriceInputRef}
                />
              </OrderPriceWrapper>
            </LeftSide>
            <RightSide>
              <KeyPad
                onNumberKeyBtnClickHandler={onNumberKeyBtnClickHandler}
                onClearButtonHandler={onClearButtonHandler}
                BackButtonHandler={BackButtonHandler}
              />
              <ButtonWrapper>
                <CloseButton onClick={() => onCloseModal()}>닫기</CloseButton>
                {/* orderprice가 0원일 때 disabled */}
                <SButton
                  buttonText="결제"
                  type={determineOrderButtonType(orderPrice)}
                  onClick={(e) => {
                    fetchHotplPassBalance(removeAllSpaces(code), orderPrice, accessToken);
                  }}
                  width={"18.1rem"}
                  height={"6.1rem"}
                />
              </ButtonWrapper>
            </RightSide>
          </ModalWrapper>
        </Modal>
      </Dim>
    </Wrapper>
  );
};

export default PosModal;

const Wrapper = styled.div``;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;
`;

const Title = styled.div`
  color: #252525;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 144.444%; /* 26px */
  letter-spacing: -0.2px;
  margin-bottom: 2.6rem;
  text-align: left;
`;

export const Dim = styled.div<{ $isopen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 72.6rem;
  height: 49.6rem;
  border-radius: 2rem;
  background: white;
  padding: 3.6rem;
`;

const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1.6rem;
  cursor: pointer;
`;

const BalanceLabel = styled.div`
  display: inline-flex;
  height: 36px;
  padding: 0 12px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #ede9ff;
  color: #6728ff;

  /* Body/Body_14_R */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.2px;
  cursor: pointer;
`;

const BalanceLabelTitle = styled.div`
  color: #6728ff;

  /* Caption/Caption_13_B */
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.2px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const TimerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const InputTimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const InputListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.7rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 0.4rem;
`;

const CloseButton = styled.button`
  display: flex;
  width: 8.7rem;
  height: 6.1rem;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #f1f3f5;

  color: #4a5561;
  text-align: center;

  /* Body/Body_16_R */
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 150% 24px */
  letter-spacing: -0.2px;
`;

const NumberKeyInput2 = styled.input`
  display: block;
  color: #343a40;
  width: calc(34.1rem - 8.9rem);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.5px;
  text-align: right;
  border: none;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &::placeholder {
    color: #4a5561;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;
  }
  cursor: auto;
`;

const OrderPriceWrapper = styled.div`
  display: flex;
  width: 34.1rem;
  height: 4rem;
  padding-bottom: 0.4rem;
  justify-content: space-between;
  align-items: flex-end;
  flex-shrink: 0;
  border-bottom: 1px solid #acb5bd;
`;

const OrderTitle = styled.h5`
  color: #343a40;
  text-align: center;

  /* Headline/Headline_26_B */
  font-size: 2.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130.769%; /* 130.769% */
  letter-spacing: -0.5px;
  white-space: nowrap; /** 텍스트를 한 줄로 처리 */
`;


const SButton = styled(Button)`
  margin-top: 1rem;
`;



