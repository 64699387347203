import axios from "axios";
import { reissueError } from "utils/errorFunction";
import { isTokenExpiringSoon, postJwtReissue } from "utils/token";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/pos/store`,
  // baseURL: `http://192.168.219.131:8080/api/pos/store`,
  headers: {
    os: process.env.REACT_APP_OS,
    version: process.env.REACT_APP_VERSION,
  },
});

api.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (isTokenExpiringSoon(refreshToken)) {
      reissueError(accessToken, refreshToken);
      return;
    }

    if (isTokenExpiringSoon(accessToken) && !config.url.includes("/reissue")) {
      // 토큰 재발급 요청이 아닌 경우에만 재발급 호출
      try {
        const newTokens = await postJwtReissue(accessToken, refreshToken);
        config.headers["Authorization"] = `Bearer ${newTokens.accessToken}`;
      } catch (error) {
        console.error("Failed to reissue token:", error);
        reissueError(accessToken, refreshToken);
        return;
      }
    } else {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const originalRequest = error.config;

    if (
      error?.response?.status === 401 &&
      !originalRequest._retry &&
      !originalRequest.url.includes("/reissue")
    ) {
      originalRequest._retry = true;
      try {
        const newTokens = await postJwtReissue(accessToken, refreshToken);
        api.defaults.headers.common.Authorization = `Bearer ${newTokens.accessToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        console.error("Token reissue failed:", refreshError);
        reissueError(accessToken, refreshToken);
        return;
      }
    }

    return Promise.reject(error);
  }
);

export const storeBranchApi = {
  deleteLogout: () => {
    const data = { accessToken: localStorage.getItem("accessToken"), refreshToken: localStorage.getItem("refreshToken") }
    return api.delete("/branch/signout", { data: data })
  }
};
