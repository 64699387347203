import React from "react";
import styled from "styled-components";
import {ReactComponent as BackIcon} from "assets/images/pos/ic_backbutton.svg";
import GhostButton from "components/common/ghostButton";

type keyPadProps = {
  onNumberKeyBtnClickHandler: (e: React.MouseEvent) => void;
  onClearButtonHandler: (e: React.MouseEvent) => void;
  BackButtonHandler: (e: React.MouseEvent) => void;
};

const keyPad = ({
  onNumberKeyBtnClickHandler,
  onClearButtonHandler,
  BackButtonHandler,
}: keyPadProps) => {
  const commonProps = {
    onClick: (e: React.MouseEvent) => onNumberKeyBtnClickHandler(e),
    width: "9rem",
    height: "6.8rem",
    $buttontextsize: "2.8rem",
    $padding: "1.6rem 2rem"
  }
  return (
    <NumberKeyWrap>
      <NumberKeyRow>
        <NumberKeyButton{...commonProps} value={7} buttonText={"7"}/>
        <NumberKeyButton{...commonProps} value={8} buttonText={"8"}/>
        <NumberKeyButton{...commonProps} value={9} buttonText={"9"}/>
      </NumberKeyRow>
      <NumberKeyRow>
        <NumberKeyButton{...commonProps} value={4} buttonText={"4"}/>
        <NumberKeyButton{...commonProps} value={5} buttonText={"5"}/>
        <NumberKeyButton{...commonProps} value={6} buttonText={"6"}/>
      </NumberKeyRow>
      <NumberKeyRow>
        <NumberKeyButton{...commonProps} value={1} buttonText={"1"}/>
        <NumberKeyButton{...commonProps} value={2} buttonText={"2"}/>
        <NumberKeyButton{...commonProps} value={3} buttonText={"3"}/>
      </NumberKeyRow>
      <NumberKeyRow>
        <NumberKeyButton{...commonProps} buttonText={"C"} onClick={onClearButtonHandler}/>
        <NumberKeyButton{...commonProps} value={0} buttonText={"0"}/>
        <NumberKeyButton{...commonProps} onClick={BackButtonHandler} icon={<BackIcon />}/>
      </NumberKeyRow>
    </NumberKeyWrap>
  );
};

export default keyPad;
// 키패드
const NumberKeyWrap = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
  z-index: 100;
  margin-bottom: 2.1rem;
`;
// 키패드 가로줄
const NumberKeyRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.6rem;
  gap: 0.2rem;
  cursor: pointer;
`;
// 키패드 버튼
const NumberKeyButton = styled(GhostButton)``;
