import React, { useState } from "react";
import { useParams } from "react-router-dom";
import OrderList from "page/orderList";

const Index = () => {
  const params = useParams();
  const [modalSee, setModalSee] = useState(true);

  return (
    <>
      <OrderList
        modalSee={modalSee}
        setModalSee={setModalSee}
        initialCode={params.code}
      />
    </>
  );
};

export default Index;
